import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.page.html',
  styleUrls: ['./invoices.page.scss'],
})
export class InvoicesPage implements OnInit {
  invoices
  all
  name=''
  status=''
  statuses
  lang=localStorage.getItem('lang')
  constructor(public api:ApiService) { 
    this.api.get('status').then(res=>this.statuses=res)
  }

  ngOnInit() {
  }

  search(){
    console.log(this.name,this.status,this.all)
    this.invoices=this.all
   // this.invoices=this.invoices.filter(item=>item.customer.name.toLowerCase().indexOf(this.name)||item.customer.mobile.toLowerCase().indexOf(this.name)||item.customer.id.toString().indexOf(this.name))
   if(this.status)
  {  this.invoices=this.invoices.filter(item=>item.status_id==this.status)}
  }

  filter(val)
{
  this.status=val 
  this.search()
}
 
  ionViewWillEnter()
  {
    this.api.get('invoice').then(res=>{
      this.all=res
      this.invoices=res}).catch(e=>console.log(e))
  }
}
