import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  start=''
  end=''
  invoices
  total_customers
  total_invoices
  total_payments 
  payments
  statuses 
  customers 
  methods
  products 
  options1:any
  options2:any
  options3:any
  options4:any
  options5:any
  options6:any
  options7:any
  options8:any
  total_cases:any

lang=localStorage.getItem('lang')??'en'
  constructor(private api:ApiService) { 
    this.getData()
  }

  ngOnInit() {
  }

  getData()
  {
    this.api.get('dash?start='+this.start+'&end='+this.end).then((res:any)=>{
      console.log(res)
      this.start=res.start
      this.end=res.end 
      this.total_customers=res.total_customers
      this.total_invoices=res.total_invoices
      this.total_payments=res.total_payments
      this.methods=res.methods 
     // this.products=res.products 
     this.total_cases=res.total_cases
      this.invoices=res.invoices
      this.payments=res.payments
      this.statuses=res.statuses
      this.products=res.products

      var invoices=[]
      // this.invoices.map(item=>{
      //   invoices.push({
      //     x:item.x,y:item.amount
      //   })
      // })



      this.options1= this.makeChart(this.invoices,this.lang=="ar"?"الطلبات":'Cases',this.lang=="ar"?"عدد الطلبات":'Cases number','chart')
      this.options2= this.makeChart(invoices,this.lang=="ar"?"العروض":'Offers',this.lang=="ar"?"قيمة العروض":'Offers amount','order_amount')
      this.options3= this.makeChart(this.invoices,this.lang=="ar"?"العملاء":'Customers',this.lang=="ar"?"العملاء":'Customer','customers')
      this.options4= this.makeChart(this.payments,this.lang=="ar"?"المدفوعات":'Payments',this.lang=="ar"?"المدفوعات":'Payments','payments')

      let series=[]
      let labels=[]
      for(let i=0;i<this.statuses.length;i++)
      {
        series.push(this.statuses[i].amount*1)
        labels.push( this.statuses[i].name_en)
      }
      this.options5=this.makePieChart(series,labels,this.lang=="ar"?"حالات الطلبات":'Invoice Cases')

      let series1=[]
     let  labels1=[]
     
      // for(let i=0;i<this.products.length;i++)
      // {
      //   series1.push((this.products[i].amount*1-this.products[i].discount*1))
      //   labels1.push( this.products[i].name)
      // }
      this.options6=this.makePieChart(series1,labels1,this.lang=="ar"?"الخدمات":'Services')

    }).catch(e=>console.log(e))
  }

  makePieChart(series,labels,title)
  {
   
    return     {
  series:  series ,
  chart: {
    height: 350,
    type: "pie"
  },
  title: {
    text: title
  },
  labels:  labels
  
}
  }

  makeChart(data,name,title,chart)
  {
    return {
      
      series: [
        {
          name: name,
          data: data
        }
      ],
      
      
      chart: {
        
        id:chart,
        height: 350,
        type: "line",
        
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: true
        }
      },
      colors: ["#77B6EA", "#545454"],
      dataLabels: {
        enabled: true,
        position: 'top'
        
      },
      stroke: {
        curve: "smooth",
       
      },
      title: {
        text: title,
        align: "left",
        
      },
      grid: {
        
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: .5
      },
      xaxis: {
       
      },
      yaxis: {
        title: {
          text: "Temperature",
          style:{
            color:'#fff'
          }
          
        },
        min: 5,
        max: 40
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: 0,
        offsetX: 0
      },
      
    
    };

  }

}
