import { NgModule } from '@angular/core';
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTableModule} from "@angular/material/table"
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatBadgeModule} from "@angular/material/badge";
import {MatExpansionModule} from "@angular/material/expansion"
import {MatMenuModule} from "@angular/material/menu"
import {MatStepperModule} from "@angular/material/stepper"
import {MatCardModule} from "@angular/material/card"
import {MatListModule} from "@angular/material/list"
import {MatChipsModule} from "@angular/material/chips"
import {MatTabsModule} from "@angular/material/tabs"
import {MatDialogModule} from "@angular/material/dialog"
import {MatDatepickerModule} from "@angular/material/datepicker"
import {MatNativeDateModule} from '@angular/material/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { DropdownComponent } from './dropdown/dropdown.component';

const material=[
  MatSlideToggleModule,
  AngularEditorModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatTabsModule,
  MatCardModule,
  MatButtonModule,
  MatExpansionModule,
  MatListModule,
  MatStepperModule,
  MatMenuModule,
  MatBadgeModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatChipsModule,
  MatDialogModule
]

@NgModule({
  declarations:[],
  imports: material,
  exports:material
})
export class MaterialModule { }
