import { Component } from '@angular/core';
import { ApiService } from './services/api.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
   // { title_en: 'Dashboard',title_ar:"لوحة التحكم", url: '/dashboard', icon: 'speedometer' ,roles:[1]},
   { title_en: 'cases',title_ar:"الطلبات", url: '/cases', icon: 'document-text',roles:[1,2] },
   { title_en: 'VIP cases',title_ar:"الطلبات المميزة", url: '/vip-cases', icon: 'star',roles:[1,2] },
   { title_en: 'VIP lawyers',title_ar:"المحامين المميزين", url: '/vip-lawyers', icon: 'star',roles:[1,2] },
   { title_en: 'VIP questions',title_ar:"اﻷسئلة المميزة", url: '/vip-questions', icon: 'help',roles:[1,2] },
   { title_en: 'Specializations',title_ar:"التخصصات", url: '/categories', icon: 'apps',roles:[1,2] },
    { title_en: 'Countries',title_ar:"الدول", url: '/countries', icon: 'location',roles:[1,2] },
    { title_en: 'Cities',title_ar:"المدن", url: '/cities', icon: 'navigate-circle',roles:[1,2] },
   // { title_en: 'customers',title_ar:"العملاء", url: '/customers', icon: 'people',roles:[1,2] },
   // { title_en: 'lawyers',title_ar:"المحامين", url: '/lowyers', icon: 'library',roles:[1] },
    { title_en: 'pages',title_ar:"صفحات الموقع", url: '/pages', icon: 'laptop',roles:[1] },
    { title_en: 'messages',title_ar:"الدردشة", url: '/messages', icon: 'mail-open',roles:[1] },
    { title_en: 'Admin messages',title_ar:"الدردشة مع الادارة", url: '/admin-messages', icon: 'mail-open',roles:[1] },
    { title_en: 'services',title_ar:"الخدمات", url: '/services', icon: 'briefcase',roles:[1] },
  //  { title_en: 'settings',title_ar:"الاعدادات", url: '/settings', icon: 'cog',roles:[1] },
    { title_en: 'users',title_ar:"مستخدمي النظام", url: '/users', icon: 'person',roles:[1] },
    { title_en: 'Logout',title_ar:"تسجيل الخروج", url: '/logout', icon: 'log-out' },
  
  ];
  user
  lang=localStorage.getItem('lang')??'en'
  constructor(private api:ApiService,private translate:TranslateService) {
   this.translate.setDefaultLang(this.lang)
   let htmlTag = window.document.getElementsByTagName('html')[0] as any;
    htmlTag.dir = this.lang === 'ar' ? 'rtl': 'ltr';

    this.translate.use(this.lang);
   
    this.api.getUser()
    this.api.user.subscribe(data=>this.user=data)
  }
}
