import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ApiService } from '../services/api.service';
import {MatDialog} from '@angular/material/dialog';
import { ManageComponent } from './manage/manage.component';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.page.html',
  styleUrls: ['./customers.page.scss'],
})
export class CustomersPage implements OnInit {
  customers
  allcustomer
  lang=localStorage.getItem('lang')??'en'
  constructor(
    private api:ApiService,
    private alertCtr:AlertController,
    public dialog: MatDialog) { 
    this.getData()
  }
  openDialog(customer?) {
    const dialogRef = this.dialog.open(ManageComponent,{data:customer});

    dialogRef.afterClosed().subscribe(result => {
      this.getData()
    });
  }


  ngOnInit() {
  }

  getData(){
    this.api.get("customer").then(res=>{
      this.allcustomer=res
      this.customers=res}).catch(e=>console.log(e))
  }

  async manage(item?)
  {
    var alert=await this.alertCtr.create({header:item?this.lang=="ar"?"تعديل عميل":"update customer":this.lang=="ar"?"اضافة عميل":"create customer",
  inputs:[
    {name:"name",placeholder:this.lang=="ar"?"الاسم":"name",value:item?.name},
    {name:"mobile",placeholder:this.lang=="ar"?"رقم الهاتف":"mobile",value:item?.mobile},
    {name:"address",placeholder:this.lang=="ar"?"العنوان":"address",value:item?.address},
    {name:"location",placeholder:this.lang=="ar"?"الموقع على الخريطة":"location",value:item?.location},
  ],buttons:[

    {text:this.lang=="ar"?"الغاء":"cancel"},
    {text:this.lang=="ar"?"حفظ":"save",
    handler:(data)=>{
      if(item)
      {
        this.api.put('customer/'+item.id,data).then(res=>this.getData()).catch(e=>console.log(e))
      }
      else {
        this.api.post('customer',data).then(res=>this.getData()).catch(e=>console.log(e))
      }
      
    }}
  ]})
  alert.present()
  }

  search_customer(e)
{
  var input=e.target.value.toLowerCase()

  this.customers=this.allcustomer
  if(input)
  {
    this.customers=this.allcustomer.filter(item=>item.name.toLowerCase().indexOf(input)>-1||item.mobile.toLowerCase().indexOf(input)>-1||item.address.toLowerCase().indexOf(input)>-1)
  }

}

}
