import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  user={email:"",password:""}
  lang=localStorage.getItem("lang")??'en'
  constructor(public api:ApiService,private trans: TranslateService) { }

  ngOnInit() {
  }

  setLang(lang)
  {
    this.lang=lang
    this.trans.setDefaultLang(lang)
    localStorage.setItem('lang',lang)
    let htmlTag = window.document.getElementsByTagName('html')[0] as any;
    htmlTag.dir = lang === 'ar' ? 'rtl': 'ltr';

    this.trans.use(lang);
    
    window.location.reload()
  }

async login()
{
	var load=await this.api.loadingCtrl.create({mode:"ios"});
	load.present()
	this.api.post('auth/login',this.user).then(async(res:any)=> {
		load.dismiss()
		localStorage.setItem('token',res.token)
		window.location.replace('/')
    }).catch(e=>{
		load.dismiss()
		if (e?.error?.error) {      
			this.api.message(e.error?.error,'danger','top')}
		else {
			this.api.message(this.lang=='ar'?"لا يوجد اتصال بالانترنت":"something went wrong","danger",'top')
		}
		console.log(e)
    })
}

}
