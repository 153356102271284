import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PaymentComponent } from 'src/app/invoices/payment/payment.component';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss'],
})
export class DetailsPage implements OnInit {

  invoice
  id
  statuses
  payments 
  methods
  drivers
  user
  url=environment.url
  lang=localStorage.getItem('lang')
  constructor(public api:ApiService,private route:ActivatedRoute,private dailog:MatDialog) {
    this.id=this.route.snapshot.paramMap.get('id')
    this.api.post('auth/me',{}).then((res:any)=>{
      this.user=res
    }).catch(e=>{})
    this.getData()
    this.api.get('status').then(res=>this.statuses=res).catch(e=>console.log(e))
    this.api.get('invoice').then(res=>this.methods=res).catch(e=>console.log(e))
    this.api.get('users').then((res:any)=>this.drivers=res.filter(item=>item.role_id==3)).catch(e=>console.log(e))
   }

   accept()
   {
    this.invoice.status_id=this.invoice.status_id==1?2:4
    this.save()
   }

   payment(payment?)
   {
     const dialogRef = this.dailog.open(PaymentComponent,
      {data:{
      payment:payment,
      invoice:this.invoice,
      mothods:this.methods
    }});
 
     dialogRef.afterClosed().subscribe(result => {
      this.getData()
      
       
     });
   }


   cal()
{
  var amount=0;
  var discount=0;
  this.invoice.sales.map(item=>{
    if(item.qty)
    {
      let price=item.discount>0?(item.price*1-item.discount*1):item.price
      amount+=item.qty*price
      discount+=item.qty*item.discount
      item.amount=item.qty*(item.price*1-item.discount*1)

    }
  })

  this.invoice.amount=amount
  this.invoice.discount=discount
}

save(){
  var link=this.user.role_id==3?'orders/':'invoice/'
  this.api.put(link+this.invoice.id,this.invoice).then(res=>{
    this.api.message(this.lang=="ar"?"تم التعديل بنجاح":'successfully updated','success','top')
    this.api.go('orders')
  }).catch(e=>console.log(e))
}

add(item)
{
  item.qty=item.qty+1
  this.cal()
}

remove(item)
{
  if(item.qty>1)
  {
    item.qty=item.qty-1
  }
  this.cal()
}

   getData()
   {
    
    this.api.get('invoice/'+this.id).then(res=>this.invoice=res).catch(e=>console.log(e))
   }

  ngOnInit() {
  }

}
