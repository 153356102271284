import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
methods
invoice 
payment={id:"",ref:"",attachment:null,invoice_id:"",amount:"",method_id:""}
lang=localStorage.getItem('lang')??'en'
  constructor( public dialogRef: DialogRef,public api:ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.api.get('methods').then(res=>this.methods=res).catch(e=>console.log(e))
      this.invoice=this.data.invoice 
      this.payment.amount=this.invoice.amount
      this.payment.invoice_id=this.invoice.id 
      if(this.data.payment)
      {
        this.payment=this.data.payment
      }
      
     }


     upload(e){
      var form=new FormData()
      form.append('file',e.target.files[0])
      this.api.post('upload',form).then((res:any)=>{
        this.payment.attachment=res.file
        console.log(res,this.payment.attachment)
      }).catch(e=>console.log(e))
     }

     save(){
      if(this.payment.id)
      {
        this.api.put('payment/'+this.payment.id,this.payment).then(res=>{
          this.dialogRef.close()
        }).catch(e=>console.log(e))
      }
      else{
        this.api.post('payment',this.payment).then(res=>{
          this.dialogRef.close()
        }).catch(e=>console.log(e))
      }
     }

  ngOnInit() {}

}
