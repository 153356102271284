import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LoginPageModule } from './login/login.module';
import { CustomersPageModule } from './customers/customers.module';
import { DashboardPageModule } from './dashboard/dashboard.module';
import { InvoicesPageModule } from './invoices/invoices.module';
import { OrdersPageModule } from './orders/orders.module';
import { ProductsPageModule } from './products/products.module';
import { UsersPageModule } from './users/users.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
     IonicModule.forRoot(), 
    AppRoutingModule,
     BrowserAnimationsModule,
     HttpClientModule,
     MaterialModule,
     LoginPageModule,
     TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CustomersPageModule,
    DashboardPageModule,
    InvoicesPageModule,
    OrdersPageModule,
    ProductsPageModule,
    UsersPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,

     
    ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
