import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Geolocation } from '@capacitor/geolocation';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.page.html',
  styleUrls: ['./orders.page.scss'],
})
export class OrdersPage implements OnInit {
  status=''
  statuses
  invoices
  all
  name
  lat=24.207500
  lng=55.744720
  lang=localStorage.getItem("lang")
  constructor(public api:ApiService) { 
  this.api.get('status').then((res:any)=>this.statuses=res.filter((item:any)=>item.id==1||item.id==3))
  }

  async getPosition()
  {
    const coordinates = await Geolocation.getCurrentPosition();
    if(coordinates)
    {this.lat= coordinates.coords.latitude
    this.lng=coordinates.coords.longitude
    this.calDistance()}

    Geolocation.watchPosition({},(res)=>{
      if(res)
     { this.lat=res.coords.latitude
      this.lng=res.coords.longitude
      this.calDistance()}
    })

    
  }
calDistance()
{
  this.all.map(item=>{
    item.distance=(this.getDistance({lat:this.lat,lng:this.lng},item.customer)/1000)

  })

  this.all=this.all.sort((a,b)=>a.distance-b.distance)
}
  ngOnInit() {
   
    
  }

  rad  (x) {
    return x * Math.PI / 180;
  };

  getDistance (p1, p2) {
    
   var R = 6378137; // Earth’s mean radius in meter
   var dLat = this.rad(p2.lat - p1.lat);
   var dLong = this.rad(p2.lng - p1.lng);
   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
     Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
     Math.sin(dLong / 2) * Math.sin(dLong / 2);
   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   var d = R * c;

   return d; // returns the distance in meter
 };

  search(){
    
    this.invoices=this.all
   // this.invoices=this.invoices.filter(item=>item.customer.name.toLowerCase().indexOf(this.name)||item.customer.mobile.toLowerCase().indexOf(this.name)||item.customer.id.toString().indexOf(this.name))
   if(this.status)
  {  this.invoices=this.invoices.filter(item=>item.status_id==this.status)}
  }

  filter(val)
{
  this.status=val 
  this.search()
}
 
  ionViewWillEnter()
  {
    this.api.get('orders').then((res:any)=>{
      this.all=res.filter(item=>item.status_id==1||item.status_id==3)
      this.invoices=this.all
      this.getPosition()
    }).catch(e=>console.log(e))
  }

}
