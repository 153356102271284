import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CustomersPage } from './customers/customers.page';
import { DashboardPage } from './dashboard/dashboard.page';
import { CreatePage } from './invoices/create/create.page';
import { DetailsPage } from './invoices/details/details.page';
import { InvoicesPage } from './invoices/invoices.page';
import { LoginPage } from './login/login.page';
import { OrdersPage } from './orders/orders.page';
import { DetailsPage as OrderPage } from './orders/details/details.page';
import { ProductsPage } from './products/products.page';
import { UsersPage } from './users/users.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'load',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    component:LoginPage,
   loadChildren:()=>import('./login/login.module').then(m=>m.LoginPageModule)
  },
  {
    path: 'products',
    component:ProductsPage,
    loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'invoices',
    component:InvoicesPage,
    loadChildren: () => import('./invoices/invoices.module').then( m => m.InvoicesPageModule)
  },
  {
    path: 'invoices/create',
    component:CreatePage,
    loadChildren: () => import('./invoices/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'orders/details/:id',
    component:OrderPage,
    loadChildren: () => import('./orders/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'invoices/details/:id',
    component:DetailsPage,
    loadChildren: () => import('./invoices/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'dashboard',
    redirectTo: 'cases',
    pathMatch: 'full'
   // component:DashboardPage,
   // loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'customers',
    component:CustomersPage,
    loadChildren: () => import('./customers/customers.module').then( m => m.CustomersPageModule)
  },
  {
    path: 'users',
    component:UsersPage,
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'load',
    loadChildren: () => import('./load/load.module').then( m => m.LoadPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'orders',
    component:OrdersPage,
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'categries',
    loadChildren: () => import('./categries/categries.module').then( m => m.CategriesPageModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module').then( m => m.ServicesPageModule)
  },
  {
    path: 'cases',
    loadChildren: () => import('./cases/cases.module').then( m => m.CasesPageModule)
  },
  {
    path: 'vip-cases',
    loadChildren: () => import('./vipcases/vip-cases.module').then( m => m.VIPCasesPageModule)
  },
  {
    path: 'vip-lawyers',
    loadChildren: () => import('./viplawyers/vip-lawyers.module').then( m => m.VIPLawyersPageModule)
  },
  {
    path: 'vip-questions',
    loadChildren: () => import('./vipquestions/vip-questions.module').then( m => m.VIPQuestionsPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'lawyers',
    loadChildren: () => import('./lawyers/lawyers.module').then( m => m.LawyersPageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'create',
    loadChildren: () => import('./create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/locations.module').then( m => m.LocationsPageModule)
  },
  {
    path: 'countries',
    loadChildren: () => import('./countries/countries.module').then( m => m.CountriesPageModule)
  },
  {
    path: 'cities',
    loadChildren: () => import('./cities/cities.module').then( m => m.CitiesPageModule)
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'admin-messages',
    loadChildren: () => import('./admin-messages/admin-messages.module').then( m => m.AdminMessagesPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
