// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url:"https://hoqouq.flogital.com/",
  // url:"http://localhost:8000/",
  firebase: {
    apiKey: "AIzaSyBqedR34TwimjHsQXsRmpgyKSM-AGHSGv0",
    authDomain: "huqouq-4f617.firebaseapp.com",
    databaseURL: "https://huqouq-4f617-default-rtdb.firebaseio.com",
    projectId: "huqouq-4f617",
    storageBucket: "huqouq-4f617.appspot.com",
    messagingSenderId: "1043823844278",
    appId: "1:1043823844278:web:414461fda912a2431b3f17",
    measurementId: "G-2GT7NXDBGW"
    // vapidKey:"BKd0_uV_AnV-jHFfI93tlrB0plQ_D1iRl372_QAQZvOhAbzDQYSzkTAMDulFQaJdhzdvX2bHqOds_m7ESGkv8q8"
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
