import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../services/api.service';
import { ManageComponent } from './manage/manage.component';

import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit {
  users 
  allUsers
  roles
  input=''
  displayedColumns: string[] = ['name', 'email', 'mobile', 'role','active','id'];
  dataSource: any;
  lang=localStorage.getItem("lang")
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public api:ApiService,private dailog:MatDialog,private alertCrl:AlertController) { 
    this.getData()
  }

 async reset(user)
  {
    var alert=await this.alertCrl.create({
      header:this.lang=="ar"?"اعادة تعيين كلمة المرور":"Password reset",
      inputs:[{placeholder:this.lang=="ar"?"كلمة المرور الجديدة":"new Password",name:"password"}],
      buttons:[
        {text:this.lang=="ar"?"الغاء":"canel"},
        {text:this.lang=="ar"?"حفظ":"save",handler:(data)=>{
          if(data.password )
          {
            this.api.post('users/reset',{id:user.id,password:data.password}).then(res=>{
              this.api.message(this.lang=="ar"?"تم اعادة تعيين كلمة المرور":'successfully reset','success','top')

            })
            .catch(e=>{
              console.log(e)
            })
          }
        }}
      ]
    })
    alert.present()
  }

  ngOnInit() {
    this.api.get('roles').then(res=>this.roles=res).catch(e=>console.log(e))
  }

  manage(user?)
  {
    const dialogRef = this.dailog.open(ManageComponent,
      {data:{
     roles:this.roles,
     user:user
    },width:"300px"});
 
     dialogRef.afterClosed().subscribe(result => {
      this.getData()
      
       
     });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }
  UserApproval(id){
    this.api.put(`admin/users/approve/${id}`,'')
    .then((res:any)=>{
      alert(res.message);
    })
    .catch(e=>console.log(e));
    this.getData()
  }

  getData(){
    this.api.get('users').then(res=>{
    this.users=res;
    this.dataSource=res 
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    }).catch(e=>console.log(e))
  }

}
