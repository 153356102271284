import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.page.html',
  styleUrls: ['./products.page.scss'],
})
export class ProductsPage implements OnInit {
  products
  lang=localStorage.getItem("lang")
  constructor(private api:ApiService,private alrtCtrl:AlertController) { 
    this.getData()
  }

  async manage(item?){
    var alert=await this.alrtCtrl.create({
      
      message:item?this.lang=="ar"?"تعديل خدمة":"product update":this.lang=="ar"?"اضافة خدمة":"product create",
      inputs:[{name:"name_ar",value:item?.name_ar,placeholder:this.lang=="ar"?"الاسم باللغة العربية":"Arabic name"},
      {name:"name_en",value:item?.name_en,placeholder:this.lang=="ar"?"الاسم باللغة الانجليزية":"Engilsh name"},
      {name:"price",value:item?.price,placeholder:this.lang=="ar"?"السعر":"price"},
      {name:"discount",value:item?.discount,placeholder:this.lang=="ar"?"التخفيض":"discount"},]
      ,
      buttons:[
      {text:this.lang=="ar"?"الغاء":"cancel"},
      {text:this.lang=="ar"?"حفظ":"save",handler:(data)=>{
        console.log(data)
        if(item)
        {
        
          this.api.put('products/'+item.id,data).then(res=>{
            this.getData()
            this.api.message(this.lang=="ar"?"تم التعديل ":'successfully updated',"success","top")
          }).catch(e=>{
            this.api.message(this.lang=="ar"?"حدث خطأ":'some thing went wrong',"danger","top")
          })
        }
        else {
          {
            this.api.post('products',data).then(res=>{
              this.getData()
              this.api.message(this.lang=="ar"?"تمت الاضافة":'successfully created',"success","top")
            }).catch(e=>{
              this.api.message(this.lang=="ar"?"حدث خطأ":'some thing went wrong',"danger","top")
            })
          }
        }
      }}
      ]
    })
    alert.present()
  }

  getData()
  {
    this.api.get('products').then(res=>this.products=res).catch(e=>console.log(e))
  }

  ngOnInit() {
  }

}
