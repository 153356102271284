import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
declare var google;
@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent implements OnInit {
  @ViewChild('cmap',{static:true}) mapElement: ElementRef;
  map: any;
  GoogleAutocomplete;
  customer:any={name:"",mobile:"",address:"",lat:24.207500,lng:55.744720}
  autocomplete= { input: '' };
  autocompleteItems=[]
  dataResult=0;
  latLng
    marker;
    load=0;

  constructor( private zone: NgZone,public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,private api:ApiService) {
      if(data)
      {
        this.customer=data
      }
     }

     save()
     {
      if(this.customer.id)
      {
        var lang=localStorage.getItem('lang')
        this.api.put('customer/'+this.customer.id,this.customer).then(res=>{
          this.api.message(lang=='ar'?"تم التعديل بنجاح":"succefuly updated",'success','top')
          this.dialogRef.close()
        })
        .catch(e=>{
          this.api.message(lang=='ar'?"حدث خطآ":"something went wrong",'danger','top')
          this.dialogRef.close()
        })
      }
      else{
        this.api.post('customer',this.customer).then(res=>{
          this.api.message(lang=='ar'?"تمت الاضافة بنجاح":"succefully created",'success','top')
          this.dialogRef.close()
        })
        .catch(e=>{
          this.api.message(lang=='ar'?"حدث خطآ":"something went wrong",'danger','top')
          this.dialogRef.close()
        })
      }
     
     }

  ngOnInit() {
    this.loadMap(this.customer.lat,this.customer.lng)
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
  }
  rad  (x) {
    return x * Math.PI / 180;
  };

  getDistance (p1, p2) {
    console.log(p1,p2)
   var R = 6378137; // Earth’s mean radius in meter
   var dLat = this.rad(p2.lat - p1.lat);
   var dLong = this.rad(p2.lng - p1.lng);
   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
     Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
     Math.sin(dLong / 2) * Math.sin(dLong / 2);
   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   var d = R * c;

   return d; // returns the distance in meter
 };

loadMap(lat,lng){
  let latitude = lat;
  let longitude = lng;
  this.customer.lat=lat;
  this.customer.lng=lng;
  
  // create a new map by passing HTMLElement
  let mapEle: HTMLElement = document.getElementById('cmap');

  // create LatLng object
  let myLatLng = {lat: latitude, lng: longitude};

  // create map
  this.map = new google.maps.Map(mapEle, {
    center: myLatLng,
    zoom: 15,
  });
  
this.addMarker()

  this.map.addListener('click', e=> {
    this.customer.lat=e.latLng.lat();
    this.customer.lng=e.latLng.lng();

  
    
this.marker.setPosition(e.latLng)

  });


}
updateSearchResults(){
    
  if (this.autocomplete.input == '') {
    this.autocompleteItems = [];
    return;
  }
  this.GoogleAutocomplete.getPlacePredictions({ input: this.autocomplete.input },
  (predictions, status) => {
    this.autocompleteItems = [];
    this.zone.run(() => {
      if(predictions)
      {
        predictions.forEach((prediction) => {
          this.autocompleteItems.push(prediction);
        });
      }
     
    });
  });
}
geocoder;
GooglePlaces;
nearbyItems
selectSearchResult(item){
  
  this.autocompleteItems = [];
  this.geocoder = new google.maps.Geocoder();
this.geocoder.geocode({'placeId': item.place_id}, (results, status) => {
    if(status === 'OK' && results[0]){
      let position = {
          lat: results[0].geometry.location.lat,
          lng: results[0].geometry.location.lng
      };
      this.customer.lat=results[0].geometry.location.lat();
      this.customer.lng=results[0].geometry.location.lng();
   
      this.marker.setPosition(results[0].geometry.location)
      this.map.setCenter(results[0].geometry.location);
    }
  })
  
  
}




addMarker(){

  var marker = new google.maps.Marker({
    map: this.map,
    animation: google.maps.Animation.DROP,
    position: this.map.getCenter()
  });
 
  let content = "<h4 tect-center>مكانك!</h4>";  
  this.marker=marker   
 
  this.addInfoWindow(marker, content);
 
}

addInfoWindow(marker, content){

  let infoWindow = new google.maps.InfoWindow({
    content: content
  });
 
  google.maps.event.addListener(marker, 'click', () => {
    infoWindow.open(this.map, marker);
  });
 
}
}
