import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
public  url="https://hoqouq.flogital.com/"
// public url = "http://localhost:8000/"

lang=localStorage.getItem('lang')??'en';
  user=new BehaviorSubject(null)
  

  public loading 
  
  constructor(public loadingCtrl: LoadingController,private http:HttpClient,private toustCrl:ToastController
    ,private route:Router) { 
   
  }

 

  get(link)
  {var token= localStorage.getItem('token')
    return new Promise((resolve,reject)=>{
      this.http.get(this.url+'api/'+link,{headers:{"Authorization":"bearer "+token}}).subscribe(res=>resolve(res),(e)=>reject(e))
    })
  }

  getUser()
  {
    this.post('auth/me',{}).then(res=>{
      this.user.next(res)
    }).catch(e=>{
      this.logout()
      console.log(e)
    })
  }

  logout()
  {
    localStorage.removeItem('token')
    this.user.next(null)
    this.go('login')
    
  }

 async message(message,color?,position?)
  {
    var toast=await this.toustCrl.create({
      message:message,
      mode:"ios",
      position:position??'top',
      color:color??"dark",
      duration:3000
    })

    toast.present()
  }

  go(link)
  {
    this.route.navigateByUrl(link)
  }

  post(link,data)
  {
    var lang=localStorage.getItem('lang')??"en"
    var token= localStorage.getItem('token')
    return new Promise((resolve,reject)=>{
      // alert(this.url+'api/'+link)
      this.http.post(this.url+'api/'+link, data,{headers:{"Authorization":"bearer "+token}}).subscribe(res=>{
        if(link.toLowerCase().indexOf('auth')>-1)
        {

        }
       else { 
       // this.message(lang=="ar"?"تمت الاضافة  بنجاح":'successfully created','success','top')
       }
        return resolve(res)
      },(e)=>reject(e))
    })
  }

  put(link,data)
  {
    var token= localStorage.getItem('token')
    var lang= localStorage.getItem('lang')
    return new Promise((resolve,reject)=>{
      this.http.put(this.url+'api/'+link, data,{headers:{"Authorization":"bearer "+token}})
      .subscribe(res=>{
        
        //this.message(lang=="ar"?"تم التعديل بنجاح":'successfully updated','success','top')
      

        return resolve(res)},(e)=>reject(e))
    })
  }

  delete(link)
  {
    var token= localStorage.getItem('token')
    return new Promise((resolve,reject)=>{
      this.http.delete(this.url+'api/'+link,{headers:{"Authorization":"bearer "+token}}).subscribe(res=>resolve(res),(e)=>reject(e))
    })
  }
}
