import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ManageComponent } from '../../customers/manage/manage.component';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-create',
  templateUrl: './create.page.html',
  styleUrls: ['./create.page.scss'],
})
export class CreatePage implements OnInit {
  customers
  allcustomer
  products
  selectedIndex=0
  invoice={customer_id:"",items:[],amount:0,discount:0}
  user
  lang=localStorage.getItem('lang')
  constructor(private api:ApiService,private alertCtr:AlertController,public dialog: MatDialog) { 
    this.getCustomer()
    this.getProducts()
    this.api.post('auth/me',{}).then((res:any)=>{
      this.user=res
    }).catch(e=>{})
  }
  openDialog(customer?) {
    const dialogRef = this.dialog.open(ManageComponent,{data:customer});

    dialogRef.afterClosed().subscribe(result => {
      this.getCustomer()
    });
  }
add(item)
{
  item.qty=item.qty?item.qty+1:1
  this.cal()
}

save()
{
  this.invoice.items=this.products.filter(item=>item.qty>0)
  var link=this.user.role_id==3?'orders':'invoice'
  var page=this.user.role_id==3?'orders':'invoices'
  this.api.post(link,this.invoice).then(res=>this.api.go(page)).catch(e=>console.log(e))
}
cal()
{
  var amount=0;
  var discount=0;
  this.products.map(item=>{
    if(item.qty)
    {
      let price=item.discount>0?(item.price*1-item.discount*1):item.price
      amount+=item.qty*price
      discount+=item.qty*item.discount

    }
  })

  this.invoice.amount=amount
  this.invoice.discount=discount
}
remove(item)
{
  if(item.qty==1)
  {
    item.qty=null
  }
  if(item.qty>1)
  {
    item.qty=item.qty-1
  }
  this.cal()
}
search_customer(e)
{
  var input=e.target.value.toLowerCase()
  console.log(input)
  this.customers=this.allcustomer
  if(input)
  {
    this.customers=this.allcustomer.filter(item=>item.name.toLowerCase().indexOf(input)>-1||item.mobile.toLowerCase().indexOf(input)>-1||item.address.toLowerCase().indexOf(input)>-1)
    console.log(this.customers)
  }

}
getProducts()
{
  this.api.get("products").then(res=>this.products=res).catch(e=>console.log(e))
}
  setCustomer(item)
  {
    this.invoice.customer_id=item.id 
    this.selectedIndex=1
    
  }

  getCustomer()
  {
    this.api.get('customer').then(res=>{this.customers=res
      this.allcustomer=res
    }).catch(e=>console.log(e))
  }

 async addCustomer()
  {
    var alert=await this.alertCtr.create({header:this.lang=="ar"?"اضافة عميل":"create customer",
  inputs:[
    {name:"name",placeholder:this.lang=="ar"?"الاسم":"name"},
    {name:"mobile",placeholder:this.lang=="ar"?"رقم الهاتف":"mobile"},
    {name:"address",placeholder:this.lang=="ar"?"العنوان":"address"},
    {name:"location",placeholder:this.lang=="ar"?"الموقع على الخريطة":"location"},
  ],buttons:[

    {text:this.lang=="ar"?"الغاء":"cancel"},
    {text:this.lang=="ar"?"حفظ":"save",
    handler:(data)=>{
      this.api.post('customer',data).then(res=>this.getCustomer()).catch(e=>console.log(e))
    }}
  ]})
  alert.present()
  }

  ngOnInit() {
  }

}
