import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog,  MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent implements OnInit {
  user={id:"",name:"",email:"",password:"",role_id:"",active:1,mobile:""}
  roles 
  constructor( public dialogRef: DialogRef,private api:ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.roles=this.data.roles 
      if(this.data.user)
      {
        this.user=this.data.user
      }
    }

    save(){
      if(this.user.id)
      {
        this.api.put('users/'+this.user.id,this.user).then(res=>{
          this.dialogRef.close()
        }).catch(e=>console.log(e))
      }
      else{
        this.api.post('users',this.user).then(res=>{
          this.dialogRef.close()
        }).catch(e=>console.log(e))
      }
     }

  ngOnInit() {}

}
